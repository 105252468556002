body {
    background-color: #000;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
}



.button-styles {
    width: 189px;
    height: 60px;
    background: #FFFFFF;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    /* identical to box height */
    color: #000000;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}


.company-logo img {
    position: absolute;
    width: 189px;
    height: 104px;
    left: 57px;
    top: 18px;
}

.company-logo,
.nav-links {
    display: inline-block;
}

.nav-links {
    position: absolute;
    right: 60px;
    top: 70px;
}

.nav-links a {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    margin-right: 45px;
    text-decoration: none;
}

.team-img  {
    object-fit: cover;
    width: 100;
    max-height: 100%;
}

.team-top {
    width: 80%;
}

.greeting {
    width:  auto;
}


.novas-industrial {
    width: 100%;
    justify-content: center;
    text-align: center;
}

.top-section {
    width: auto;
    text-align: center;
}

.home-tex {
    justify-content: center;
    text-align: center;
    background-color: goldenrod;
    width: 1100px;
    height: 120px;
}

.home-text h2 {
    height: 29px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 29px;
    color: #8DC74A;
    text-align: center;
}

.home-text h4 {
    text-align: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 29px;
    text-align: center;
    color: #FFFFFF;
}

.home-text p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 29px;
    text-align: center;
    color: #FFFFFF;
}

.home-text Button {
    width: 179px;
    height: 60px;
    background: #FFFFFF;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    /* identical to box height */
    color: #000000;
}

.home-text Button:hover {
    background-color: #383536;
    color: #8DC74A;
    height: 70px;
    width: 195px;
    transition: ease 0.8;
    border-radius: 4px;
}

.middle-section {
    background-color: #000;
    margin-bottom: 10px;
    width: auto;
    overflow: visible;
    justify-content: center;
}


.best-sellers h4 {
    position: absolute;
    width: 136px;
    height: 29px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    color: #FFFFFF;
}

.product-line {
    display: inline-flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
}

.item {
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    text-align: center;
}

.middle-section h2 {
    /* margin-left: 32px; */
    color: #000;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
}

.lower-section {
    background-color: #fff;
    width: auto;
}

.new-products h4 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
}


.products-text h2 {
    position: absolute;
    width: 134px;
    height: 29px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    /* identical to box height */
    color: #8DC74A;
}

.products-text p {
    position: absolute;
    width: 533px;
    height: 258px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #FFFFFF;
}

.products-text button {
    position: absolute;
    width: 189px;
    height: 50px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    /* identical to box height */
    color: #000000;
}

.products-text button:hover {
    background-color: #383536;
    color: #8DC74A;
    height: 70px;
    width: 195px;
    transition: ease 0.8;
    border-radius: 4px;
}

.new-products-section {
    background-color: #fff;
    height: 380px;
    width: auto;
}

.item h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
    color: #000;
}

.new-products {
    position: absolute;
    width: 233px;
    height: 224px;
    margin-right: 5px;
}

.industrial-text {
    width: 50%;
}

.industrial-text h2 {
    width: 185px;
    height: 29px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    /* identical to box height */
    color: #8DC74A;
}

.industrial-text p {
    position: absolute;
    width: 543px;
    height: 258px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #FFFFFF;
}

.industrial-text button {
    position: absolute;
    width: 189px;
    height: 50px;
    background-color: #FFFFFF;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    /* identical to box height */
    color: #000000;
}

.industrial-text button:hover {
    background-color: #383536;
    color: #8DC74A;
    height: 70px;
    width: 195px;
    transition: ease 0.8;
    border-radius: 4px;
}

.product-line img {
    width: 234px;
    height: 224px;
    margin-top: 70px;
}

.industrial-image {
    width: 80%;
}

.new-products img {
    position: absolute;
    /* width: 233px; */
    height: 224px;
    left: 82px;
    /* top: 1620px; */
}

@media (max-width: 600px) {
    .team-top {
        width: 150x;
    }
}

@media (max-width: 900px) {
    .team-top {
        
    }
}
