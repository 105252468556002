.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* App.css */
#root {
  text-align: center;
  margin: 1.2rem;
}

nav {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 2rem;
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

@import "~bootstrap/dist/css/bootstrap.css";

:root {
   --primary-color: #8dc74a;
   --secondary-color: #000;
}

body {
    background-color: #000000;
    color: #fff;
}

.header {
    background-color: #000;
    height: 150px;
}

/* Footer styling */
.footer {
    display: flex;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    margin-top: 30px;
    margin-bottom: 20px;
}

.footer-1 {
    margin-left: 22px;
}

.footer h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    margin-top: 38px;
}

.footer h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
}

.footer-4 ::placeholder {
    display: grid;
    vertical-align: middle;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
    align-content: center;
}

.footer-4 input {
    margin-left: -17px;
    background: #D9D9D9;
}

.footer-4 button {
    width: 50px;
    height: 31px;
    background-color: #d9d9d9;
    color: #000;
    margin-left: 9px;
    font-size: 14px;
    margin-top: 10px;
}

.footer-4 button:hover {
    background-color: #383536;
    color: #8DC74A;
    height: 35px;
    width: 55px;
    transition: ease 0.8;
    border-radius: 4px;
}





