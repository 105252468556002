.faq-header {
    color: #000;
    font-size: 30px;
    display: flex;
    justify-content: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
}



.accordion-header {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
}

.accordion-body {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 29px;
    color: #000000;
}

.accordion-con {
    margin-top: 30px;
}

.cd {
    background-color: #fff;
    height: 500px;
    margin-bottom: 0px;
}