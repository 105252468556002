.detail-title {
    background-color: #fff;
    color: #000;
    height: 50%;
    margin-bottom: 2vh;
    width: 100%;
}

.logoContainer {
    display: flex ;
    justify-content: center;
    align-items: center;
}

.partnerLogos img {
    width: 80% !important;
    height: 8vh;
    object-fit: contain;
}

.partnerLogos1 img {
    width: 10vw !important;
    height: 12vh;
    object-fit: contain;
}
.detail-title h1 {
    position: absolute;
    left: 0;
    right: 0;
    margin-top: 16px;
    display: flex;
    justify-content: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
    color: #000000;
}

.detail-title p:hover {
    background-color: "#84878C";
    border: "2px solid #84878C";
    color: "#000"
}


.back-nav a {
    position: absolute;
    left: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;

    line-height: 29px;
    color: #000000;
    margin-left: 12px;
    text-decoration: none;
}

/* .main {
    width: 100%;
    justify-content: center;
    text-align: center;
}  */

.main-left {
    height: 55vh;
    justify-content: center;
    display: 'flex',
}

.main-right {
    height: 55vh;
}

.main-left img {
    margin: '0 auto';
    height: 30vh;
}

.product-name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 27px;
    line-height: 29px;
    color: #FFFFFF;
    position: absolute;
    width: 640px;
    height: 258px;
    left: 810px;
    top: 300px;
}

/* page */

.product-description {
    color: #000;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin-bottom: 5vw;
}

.product-specs {
    color: #FFFFFF;
    font-family: 'Inter';
    font-style: normal;
    width: 100%;
    text-align: left;
    margin-bottom: 5vw;
}


.product-benefits {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
    color: #FFFFFF;
    text-align: left;
}

.main-right h3 {
    height: 29px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
    color: #FFFFFF;
    text-align: left;
}

.size-container {
    display: flex;
    flex-direction: row;
    justify-content: left;

}

@media only screen and (max-width: 900px) {
    .partnerLogos img, 
    .partnerLogos1 img {
        width: 20vw;
    }
}

@media only screen and (max-width: 600px) {

   .partnerLogos img, .partnerLogos1 img {
    width: 20vw;
   }


    .product-description {
        color: #FFFFFF;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        width: 100%;
        text-align: center;
        margin-bottom: 5vw;
    }

    .product-specs {
        color: #FFFFFF;
        font-family: 'Inter';
        font-style: normal;
        width: 100%;
        text-align: center;
        margin-bottom: 5vw;
    }


    .product-benefits {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 29px;
        color: #FFFFFF;
        text-align: center;
    }

    .main-right h3 {
        height: 29px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 29px;
        color: #FFFFFF;
        text-align: left;
    }

    .cc {
        display: flex;
        justify-content: center;
    }

    .size-container {
        display: flex;
        flex-direction: row;
        position: absolute;
        justify-content: center;
        width: 100%;
    }

    .size-container p {
        margin-right: 5vw;
    }

    .main-right {
        height: 77vh;
    }

    @media screen and (max-width: 600px) {
        .main-left img {
            width: 200px;
        }

        .size-p {
            padding-bottom: 12px;
            font-size: 13px;
        }
    }
}