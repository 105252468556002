.map-styling {
    margin-left: 25px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
}

.contact-page-container {
    background-color: #fff;

}

.MuiTextField-root {
    margin-bottom: 12px;
}

.contact-section h4 {
    color: #000;
    float: left;
}

.contact-section h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    line-height: 44px;
    color: #000000;
    margin-top: 14px;
    margin-bottom: 8px;
}

.contact-input {
    display: flex;
    flex-wrap: wrap;
}

.contact-page-container h1 {
    display: flex;
    justify-content: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    color: #000;
}

.contact-info,
.contact-input {
    display: grid;
    display: inline-block;
    margin-top: 78px;
}


.contact-inner {
    text-align: left;
    width: 80%;
}


.contact-inner h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    /* identical to box height */
    color: #000000;
 
}

.contact-inner h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
    color: #000000;
    /* width: 104px; */


}

.text-style {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
    color: #8DC74A;

}


.text-style-3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #56C685;
    margin-left: 100px;
}

.email {
    margin-top: 50px;
}

.contact-input h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
}

.content input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 404px;
    height: 33px;
    margin-right: 77px;
    margin-bottom: 12px;
    border-radius: 4px;
}

.name {
    margin-top: 7px;
    margin-bottom: 0px;
}

.maps-container h2 {
    display: flex;
    justify-content: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 44px;
    color: #000000;
    margin-top: 45px;
    margin-bottom: 12px;
}


.novas-industrial-map h2 {
    display: flex;
    justify-content: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 44px;
    color: #000000;
    margin-top: 45px;
    margin-bottom: 12px;
}


.address {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;

    color: #000000;
    margin-top: 20px;
    float: left;
}

.contact-details {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    border: 0;
    outline: 0;
    width: auto;
    margin: 0 auto;
}

form {
    display: flex;
    flex-direction: column;
}

.ctn-number {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
    color: #8DC74A;
}

.ctn-btn {
    background-color: #000;
    color: #fff;
}

.ctn-btn:hover {
    background-color: #383536;
    color: #8DC74A;
}

