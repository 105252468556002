.products-container {
    display: flex;
    background-color: #fff;
    flex-wrap: wrap;
    /* justify-content: center; */
  
}


.dd {
    align-items: center;
    align-content: center;
    background-color: "#fff";
}

.item {
    background-color: #fff;
    width: 233px;
    height: 330px;
    margin: 15px 10px;
    box-sizing: border-box;
    border-left: 1px solid lightgrey;
    border-right: 1px solid lightgrey;
    border-top: 1px solid lightgrey;
    border-bottom: 0px;
    /* border: 2px solid lightgrey; */
    box-shadow: 0 4px 3px -2px gray;
    backdrop-filter: blur(4px);
    border-radius: 6px;
}

.item img {
    display: flex;
    margin: 0 7px;
    justify-content: center;
    width: 200px;
    height: 202px;
    /* border: 1.5px solid #000; */
    margin-top: 10px;
    margin-bottom: 10px;
}

.item h2 {
    display: flex;
    justify-content: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 29px;
    color: #000;
    margin-top: 8px;
}

.item a {
    display: flex;
    background-color: #fff;
    text-decoration: none;
    border: #000 1px solid;
    border-radius: 4px;
    height: 30px;
    width: 200px;
    margin-left: 12px;
}


.item p {
    margin-top: 3px;
    margin-bottom: 6px;
    margin-left: 52px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    color: #000000;
}

.item a:hover {
    background-color:#84878C;
    border: 2px solid #84878C;
}

@media screen and (min-width: 300px) and (max-width: 450px) {

    .flex-container {
        display: flex;
        justify-content: center;
    }

    .item {
        margin: 30px 0;
    }
}


@media screen and (min-width: 451px) and (max-width: 600px) {

    .flex-container {
        display: flex;
        justify-content: center;
    }

    .item {
        margin: 30px 10px;
    }
}

@media screen and (min-width: 601px) and (max-width: 750px) {

    .flex-container {
        display: grid;
        /* justify-content: center; */
        grid-template-columns: 1fr;
    }

    .item {
        margin: 30px 30px;
    }
}

@media screen and (min-width: 751px) and (max-width: 950px) {

    .flex-container {
        display: grid;
        /* justify-content: center; */
        grid-template-columns: 1fr;

    }

    .item {
        display: grid;
        justify-self: center;
        height: 380px;
        width: 283px;
        /* margin: 30px 30px; */
        /* margin-left: 80px; */
    }

    .item h2 {
        display: grid;
        /* justify-content: center; */
    }

    .item img {
        height: 256px;
        width: 264px;
    }

    .item a {
        display: grid;
        justify-content: center;
        margin-bottom: 8px;
        height: 40px;
        width: 256px;
    }

    .item p {
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 0px;
    }
}

@media screen and (min-width: 951px) and (max-width: 1280px) {
    .item p {
        font-size: 16px;
    }
}
